/**
 * Clase que se encarga de deshabilitar los options de los dias que sobran para determinado mes y año.
 * La clase obtine los selects de dia, mes y año. Cuando hay un cambio en la fecha deshabilita o habilita
 * los dias seleccionables para ese mes y año.
 * El elemento que contiene los dias tiene que ser un select
 * El elemento que contiene los meses tiene que guardar el valor como integer (no funciona si el valor es por ejemplo "agosto")
 * 
 * Ejemplo de uso:
 * var day = document.querySelector('.js-day'),
 *     month = document.querySelector('.js-month'),
 *     year = document.querySelector('.js-year');
 * var ml = new MonthLenght().init(day, month, year);
 *
 * @author mreboredo
 */
(function(window, document, undefined) {
    'use strict';

    function MonthLenght() {
        var cThis = this,
            dayInput,
            monthInput,
            yearInput;


        /**
         * Agrega los event listeners para el evento de change en los selects
         * @return void
         */
        function addEventListeners(){
            monthInput.addEventListener('change', changeHandler);
            yearInput.addEventListener('change', changeHandler);
        }

        /**
         * Event handler para el change de los selects.
         * obtiene el valor del mes y año, con esto calcula la cantidad de dias que
         * tiene el mes.
         * @return void
         */
        function changeHandler(e){

            var intMonth    = monthInput.value - 1,//zero based
                intYear     = yearInput.value,
                monthStart  = new Date(intYear, intMonth, 1),
                monthEnd    = new Date(intYear, intMonth + 1, 1),
                monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
            disableOptions(monthLength);
        }

        /**
         * deshabilita los options que sobran para el mes y año seleccionado
         * @param int cantidad de dias que tiene el mes
         * @return void
         */
        function disableOptions(intLenght){
            var options = dayInput.querySelectorAll('option'),
                optionsLenght = options.length;
            for (var i = 0; i < optionsLenght; i++) {
                if(i > intLenght){
                    options[i].disabled = true;
                    if(i == dayInput.value){
                        dayInput[0].selected = 'true';
                    }
                }else{
                    options[i].disabled = false;
                }
            }
        }

        /**
         * "constructor" del closure.
         * obtiene los selects de la fecha de nacimiento y los guarda en una variable.
         * @param html select con los dias
         * @param html select con los meses
         * @param html select con los años
         * @return void
         */
        this.init = function(day, month, year){
            dayInput = day;
            monthInput = month;
            yearInput = year;
            addEventListeners();
        }
    }
    //make the class globaly visible
    window.MonthLenght = MonthLenght;
})(window, document);
